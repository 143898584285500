
import {computed, defineComponent, ref} from "vue";
import {useStore} from "vuex";
import Row from "@/components/base/common/Row.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import {LoadPanel} from "@/core/composite/composite";
import QuickAction from "@/components/base/action/QuickAction.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import InsuredForm from "@/views/insured/InsuredForm.vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import InsuredType from "@/components/base/common/InsuredType.vue";
import Contacts from "@/views/contact/Contacts.vue";
import Timelines from "@/views/timeline/Timelines.vue";
import DecodeId from "@/components/base/DecodeId.vue";
import InsuredStatus from "@/views/insured/InsuredStatus.vue";

export default defineComponent({
  name: "Overview",
  components: {
    InsuredStatus,
    DecodeId,
    Timelines, Contacts, InsuredType, InsuredForm, BaseModal, QuickAction, DateTimeFormat,  Row
  },
  setup() {
    const store = useStore();
    const policy = computed(() => store.state.PolicyModule.policy)
    const insuredModel = ref({type: 'INSURED', firstName: '', lastName: '', dob: '', gender: '', policyId: '', status :'ACTIVE'})
    const insuredId = ref('')
    const modalName = ref('Add Insured');
    return {
      policy,
      ...LoadPanel(),
      insuredModel,
      modalName,
      insuredId
    }
  },
  methods: {
    onAddInsured() {
      this.insuredModel = {type: 'INSURED', firstName: '', lastName: '', dob: '', gender: 'F', policyId: this.policy.id, status: 'ACTIVE'}
      this.insuredId = ''
      this.modalName = 'Add Insured'
      const baseModal = this.$refs.insuredModelRef as typeof BaseModal
      baseModal.showBaseModal()
    },

    onEditInsured(insured: any) {
      this.insuredModel = {
        type: insured.type.code,
        firstName: insured.person.firstName,
        lastName: insured.person.lastName,
        dob: insured.person.dob,
        status : insured.status,
        gender: insured.person.gender, policyId: this.policy.id
      }
      this.insuredId = insured.id;
      this.modalName = 'Edit Insured'
      const baseModal = this.$refs.insuredModelRef as typeof BaseModal
      baseModal.showBaseModal()
    },
    onSaveInsured() {
      const baseModal = this.$refs.insuredModelRef as typeof BaseModal;
      baseModal.hideBaseModal();
      store.dispatch(Actions.LOAD_POLICY, this.policy.id)
    }
  }
})
